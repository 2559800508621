    $('.scrollTo').click(function (event) {
        event.preventDefault();
        let to = $(this).attr('data-target');
        let close = $(this).attr('data-close-target');

        if (close) {
            $(close).removeClass('open');
        }

        $('html, body').animate({
            scrollTop: $(to).offset().top - 150
        }, 1000);
    });


    let hash = location.hash;

    if (hash) {
        let noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);

        let target = $(hash + '_load_scroll');

        $('html, body').animate({
            scrollTop: target.offset().top - 150
        }, 1000);
    }
