require('@fancyapps/fancybox');

$(document).ready(function() {
    $().fancybox({
        selector : '.owl-single-house.owl-item:not(.cloned) a',
        hash   : false,
        thumbs : {
          autoStart : true
        },
        buttons : [
          'zoom',
          'close'
        ]
    });
});