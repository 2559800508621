$(window).on("scroll", function () {
    if ($(window).scrollTop()) {
        $('.header').addClass('nav_bg');
    } else {
        $('.header').removeClass('nav_bg');
    }
});

$(document).click(function (event) {
    $('.navbar-collapse').collapse('hide');
});