require('owl.carousel/dist/owl.carousel.min')

$(function () {    
    $('.owl-nav-footer').owlCarousel({
        loop:true,
        autoplay:true,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            }
        }
    });
    $('.owl-partners').owlCarousel({
        loop:true,
        autoplay:true,
        margin:10,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:4
            }
        }
    });
    $('.owl-card').owlCarousel({
        loop:true,
        autoplay:true,
        margin:10,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            }
        }
    });
    $('.owl-card-2').owlCarousel({
        loop:true,
        autoplay:true,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:1
            },
        }
    });
    $('.owl-house-cover').owlCarousel({
        loop:true,
        autoplay:true,
        margin:10,
        nav:false,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:1
            }
        }
    });
    $('.owl-card-latest').owlCarousel({
        loop:true,
        autoplay:true,
        margin:10,
        nav:true,
        dots:false,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            },
            1200:{
                items:4
            }
        }
    });
    $('.owl-single-house').owlCarousel({
        loop:true,
        autoplay:true,
        nav:true,
        dots:false,
        lazyLoad: true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:1
            }
        }
    });
});
